<template>
        <div class="add-task-modal" v-click-outside="onClickOutside">
            <h3 class="add-task-modal--title">
                Edit task
            </h3>
            <form class="add-task-modal__form">
                <button href="" class="close_X" @click="$emit('close')">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.33301 14.6667L7.99967 7.99999M14.6663 1.33333L7.99967 7.99999M7.99967 7.99999L14.6663 14.6667M7.99967 7.99999L1.33301 1.33333" stroke="#E7E7E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                <div class="add-task-modal__item">
                    <p class="add-task-modal__name">
                        <label for="">
                            Task name
                        </label>
                        <input type="text">
                    </p>
                </div>
                <div class="add-task__proj">
                        <label for="">Project</label>
                        <v-selectize v-model="selected" :options="['Project 1 - Client','Project 2 - Client', 'Project 3 - Client']"/>
                    </div>
                <div class="buttons">
                    <button class="add-task-modal__create">
                        Save
                    </button>
                    <button @click="$emit('close')">
                        Cancel
                    </button>
                </div>
            </form>
        </div>
</template>

<script>
    import vClickOutside from 'v-click-outside'
    import 'selectize/dist/css/selectize.default.css'
    import VSelectize from '@isneezy/vue-selectize'

    
    export default {
        directives: {
        clickOutside: vClickOutside.directive
        },
        methods: {
        onClickOutside () {
            this.$emit('close')
        }
        },
        components: {
            VSelectize
        },
        data(){
            return{
                selected:'Project - Client'
            }
        }
    };
</script>

<style scoped>

    p{
        margin: 0;
        padding: 0;
    }

    .add-task-modal{
        width: 549px;
        height: 310px;
        background-color: #fff;
        position: fixed;
        top: 20%;
        padding: 20px;
        color: #222222;
        box-sizing: border-box;
        margin-bottom: 50px;
        z-index: 20;
        right: calc( 50vw - 269px);
        margin-left: -273px;
    }

    .add-task-modal--title{
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        margin: 0;
        padding-bottom: 25px;
        border-bottom: 2px solid #F2F3F6;
    }

    .add-task-modal__form{
        margin-top: 10px;
    }


    .add-task-modal__name{
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        text-align: left;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .add-task-modal__item{
        display: flex;
        flex-direction: row;
    }

    .add-task-modal__name input{
        margin-top: 5px;
        border-radius: 30px;
        height: 42px;
        border: 2px solid #F2F3F6;
        font-family: Montserrat;
        outline: none;
        padding: 0 15px;
        width: 100%;
        box-sizing: border-box;
    }

    button{
        border: none;
        background: none;
        cursor: pointer;
    }

    .buttons{
        display: flex;
        flex-direction: row-reverse;
    }

    .buttons button{
        margin-left: 10px;
        margin-top: 10px;
        width: 107px;
        height: 42px;
        font-family: 'Montserrat', sans-serif;
        background: #9CEDFF1A;
        border: 2px solid #9CEDFF;
        height: 42px;
        border-radius:30px;
        font-weight: 600;
        font-size: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .buttons .add-task-modal__create{
        background-color: #9CEDFF;
    }

    @media(max-width:800px){
        .bg{
            left: 0;
        }
    }

    
    @media(max-width:600px){
        .add-task-modal{
            width: 100vw;
            margin-left: -75px;
            right: 0;
            box-sizing: border-box;
        }
    }

    .close_X{
        position: absolute;
        right: 19px;
        top: 23px;
    }

    .add-task__proj{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        margin-top: 20px;
    }

    .add-task__proj label{
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        padding-bottom: 5px;
    }

    .add-task__project{
        width: 100%;
        height: 42px;
        border: 2px solid #F2F3F6;
        border-radius: 30px;
        cursor: pointer;
        box-sizing: border-box;
    }   

    .add-task__proj label{
        position: relative;
        margin-bottom: 5px;
    }

    .add-task__proj label:after{
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background-image: url('../../assets/img/Rectangle.svg');
        background-repeat: no-repeat;
        background-position: center;
        bottom: -32px;
        right: 16px;
        cursor: pointer;
        background-size: contain;
    }


</style>