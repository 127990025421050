<template>
    <div class="project-item-info">
        <div class="project-item__info-wrp">
            <div class="project-item__title">
                <p class="project-item__main-title">{{ projectTitle }}</p>
                <span>{{ clientTitle }}</span>
            </div>
            <p class="project-item__star" @click="changeFav()"> 
                <svg v-if="isFavorite == 0" width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 1.98689L15.1168 7.33804C15.3994 7.82324 15.8729 8.1673 16.4217 8.28614L22.4741 9.59676L18.348 14.2146C17.9739 14.6333 17.793 15.19 17.8495 15.7486L18.4733 21.9098L12.8065 19.4126C12.2927 19.1862 11.7073 19.1862 11.1935 19.4126L5.52666 21.9098L6.15047 15.7486C6.20703 15.19 6.02615 14.6333 5.65203 14.2146L1.52591 9.59676L7.57829 8.28614C8.12708 8.1673 8.60063 7.82325 8.88323 7.33805L12 1.98689Z" stroke="#FFD351" stroke-width="2" />
                </svg>
                <svg v-else width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 1.98689L15.1168 7.33804C15.3994 7.82324 15.8729 8.1673 16.4217 8.28614L22.4741 9.59676L18.348 14.2146C17.9739 14.6333 17.793 15.19 17.8495 15.7486L18.4733 21.9098L12.8065 19.4126C12.2927 19.1862 11.7073 19.1862 11.1935 19.4126L5.52666 21.9098L6.15047 15.7486C6.20703 15.19 6.02615 14.6333 5.65203 14.2146L1.52591 9.59676L7.57829 8.28614C8.12708 8.1673 8.60063 7.82325 8.88323 7.33805L12 1.98689Z" stroke="#FFD351" stroke-width="2" fill="#FFD351"/>
                </svg>
            </p>
            <p class="project-item__dots" @click="showMiniProjectModal = !showMiniProjectModal">
                <svg width="4" height="20" viewBox="0 0 4 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 10C0 8.89543 0.89543 8 2 8C3.10457 8 4 8.89543 4 10C4 11.1046 3.10457 12 2 12C0.89543 12 0 11.1046 0 10Z" fill="#BABCBF"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 2C0 0.89543 0.89543 0 2 0C3.10457 0 4 0.89543 4 2C4 3.10457 3.10457 4 2 4C0.89543 4 0 3.10457 0 2Z" fill="#BABCBF"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 18C0 16.8954 0.89543 16 2 16C3.10457 16 4 16.8954 4 18C4 19.1046 3.10457 20 2 20C0.89543 20 0 19.1046 0 18Z" fill="#BABCBF"/>
                </svg>
            </p>
        </div>
        <vue-tabs>
            <v-tab title="Tasks">
                <ProjectItemInfo :updateFilters="updateFilters"  @toggleAddTask="toggleAddTask"/>
                <ProjectItemTasks :tasks="taskStore.tasks" />
            </v-tab>
            <v-tab title="Access">
                <ProjectItemInfo  @toggleAddTask="toggleAddTask"/>
                <ProjectItemAccess :tasks="taskStore.tasks" />
            </v-tab>
            <v-tab v-if="showStatus" title="Status">
                <ProjectItemStatus />
            </v-tab>
            <v-tab title="Note">
                <ProjectItemNote />
            </v-tab>
            <v-tab title="Project Settings">
                <ProjectItemSettings />
            </v-tab>
        </vue-tabs>
        <MiniProjectModal :showProModal="showProModal" v-if="showMiniProjectModal" @close="showMiniProjectModal = false"/>
        <div class="bg" v-if="showAddTaskModal" @close="showAddTaskModal = false"></div>
        <AddTaskModal  v-if="showAddTaskModal" @close="showAddTaskModal = false"/>
        <AvailableWithProModal v-if="showAvailbleWithPro" @close="showAvailbleWithPro = false"/>
    </div>
</template>

<script>
    import ProjectItemInfo from '@/components/ProjectItem/ProjectItemInfo'
    import MiniProjectModal from '@/components/Modal/MiniProjectModal'
    import ProjectItemTasks from '@/components/ProjectItem/ProjectItemTasks'
    import ProjectItemAccess from '@/components/ProjectItem/ProjectItemAccess'
    import ProjectItemStatus from '@/components/ProjectItem/ProjectItemStatus'
    import ProjectItemNote from '@/components/ProjectItem/ProjectItemNote'
    import ProjectItemSettings from '@/components/ProjectItem/ProjectItemSettings'
    import AddTaskModal from '@/components/Modal/AddTaskModal'
    import AvailableWithProModal from '@/components/Modal/AvailableWithProModal'

    import { useProjectStore } from '@/store/ProjectStore'
    import { useTaskStore } from '@/store/TaskStore'

    import {VueTabs, VTab} from 'vue-nav-tabs'
    import 'vue-nav-tabs/themes/vue-tabs.css'

    export default {
        data(){
            const projectStore = useProjectStore() 
            const taskStore = useTaskStore()

            projectStore.setCurrentProjectById(this.$route.params.id)
            taskStore.setProject(projectStore.currentProject, this.updateTasks)

            return {
                isFavorite: projectStore.currentProject ? projectStore.currentProject.f==1 : 0,
                clientTitle: projectStore.currentProject ? projectStore.currentProject.ct : '',
                projectTitle: projectStore.currentProject ? projectStore.currentProject.t : '',
                showMiniProjectModal: false,
                showAddTaskModal: false,
                projectStore: projectStore,
                taskStore: taskStore,
                filter: taskStore.filter,
                search: taskStore.search,
                showAvailbleWithPro: false,
                showStatus: false
            }
        },
        components: {
            AddTaskModal, MiniProjectModal, ProjectItemSettings, ProjectItemNote, ProjectItemAccess, ProjectItemTasks, ProjectItemInfo, ProjectItemStatus, VueTabs, VTab,
            AvailableWithProModal
        },
        watch: {
            filter() {
                this.$forceUpdate()
            },
            search() {
                this.$forceUpdate()
            }
        },
        created(){
            if ( !this.projectStore.isCurrentProject ){
                console.log('Redirect to project list')
                this.$router.push({ name: "Projects"})
            }
        },
        methods: {
            showProModal(){
                this.showAvailbleWithPro = true
            },
            changeFav(){
                this.isFavorite = !this.isFavorite
                this.projectStore.updateFav(this.projectStore.currentProject, this.isFavorite)
            },
            updateFilters(){
                this.$forceUpdate()
            },
            toggleAddTask(){
                this.showAddTaskModal = !this.showAddTaskModal
            },
            updateTasks(tasks){
                this.$forceUpdate()
            }
        }
    }
</script>

<style>
    .project-tasks__wrp p{
        margin: 0;
        padding: 0;
    }

    .project-item__main-title{
        padding: 0;
        margin: 0;
    }

    .project-item-info{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;
        width: 100%;
        max-width: calc(100vw - 263px);
        transition: all .3s ease;
        position: relative;
    }

    .close .project-item-info{
        max-width: calc(100vw - 66px);
        transition: all .3s ease;
    }

    .project-item__title{
        padding: 15px 20px 10px 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .project-item__title span{
        font-size: 14px;
        font-weight: 600;
        color: #606569;
        margin-left: 10px;
        padding-top: 5px;
    }

    .project-item__info-wrp{
        display: flex;
        flex-direction: row;
        width: 860px;
        background-color: #fff;
        margin: 15px 0 0 30px;
        box-shadow: -1px -25px 31px -3px rgba(34, 60, 80, 0.05);
        z-index: 4;
        border-radius: 30px 30px 0 0;
        box-sizing: border-box;
        height: 58px;
        position: relative;
    }

    .project-item__star{
        margin-left: auto;
        padding: 0 15px 0 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 10px;
    }

    .project-item__star svg{
        cursor: pointer;
    }

    .project-item-info .vue-tabs .nav-tabs > li:last-child > a{
        padding-right: 15px; 
    }

    .project-item__dots{
        margin-right: 30px;
        padding: 0 10px 0 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 10px;
        cursor: pointer;
        position: relative;
    }

    .project-item__main-title{
        font-size: 26px;
        font-weight: 600;
        line-height: 32px;
    }

    .project-item-info .nav-tabs-navigation{
        margin-top: -58px;
        margin-left: 285px;
        z-index: 3;
        width: 500px;
    }

    .project-item-info .nav-tabs-wrapper{
        height: 56px;
        width: 500px;
    }

    .project-item-info .vue-tabs{
        width: 100%;
        z-index: 4;
    }

    .project-item-info .vue-tabs .nav-tabs{
        border: none;
        width: 500px;
        display: inline-block;
        flex-wrap: nowrap;
    }

    .project-item-info .vue-tabs .nav-tabs > li{
        height: 56px;
        box-shadow: 6px -10px 23px -10px rgba(34, 60, 80, 0.05);
    }

    .project-item-info .vue-tabs .nav-tabs > li > a{
        line-height: 44px;
        border: none;
        margin: 0;
        font-size: 16px;
        font-weight: 600;
        color: #222222;
        border-top: 2px solid #fff;
        border-radius: 0;
    }

    .project-item-info .vue-tabs .nav-tabs > li.active > a{
        border: none;
        border-top: 2px solid #9DE8FF;
        height: 36px;
        margin: 0;
        background: rgba(156, 237, 255, 0.1);;
        border-radius: 0;
        font-size: 16px;
        font-weight: 600;
        color: #222222;
    }

    .project-item-info .vue-tabs .nav-tabs > li.active > a,
    .project-item-info .vue-tabs .nav-tabs > li.active > a:hover,
    .project-item-info .vue-tabs .nav-tabs > li.active > a:focus{
        height: 34px;
        color: #222222;
        background: rgba(156, 237, 255, 0.1);;
        border: none;
        border-top: 2px solid rgba(156, 237, 255, 0.1);;
    }


    .project-item-info div.vue-tabs .nav-tabs > li.active > a:focus{
        color: #222222;
        height: 34px;
        background: rgba(156, 237, 255, 0.1);;
        border: none;
        border-top: 2px solid #9DE8FF;
    }

    .project-item-info .vue-tabs .nav-tabs > li.active > a{
        border-top: 2px solid #9DE8FF;
        height: 34px;
    }
    
    .project-item-info .vue-tabs .nav > li > a:hover,
    .project-item-info .vue-tabs .nav > li > a:focus{
        background: rgba(156, 237, 255, 0.1);;
        border: none;
        height: 34px;
        border-top: 2px solid rgba(156, 237, 255, 0.1);;
    }

    .project-item-info .vue-tabs .nav-tabs > li{
        background: #fff;
    }

    .project-item-info .vue-tabs .nav-tabs > li:last-child,
    .project-item-info .vue-tabs .nav-tabs > li:last-child > a{
        border-radius: 0 0 0 0;
    }      

    @media(max-width: 1250px){
        .project-item__info-wrp{
            width: 480px;
        }
        .project-item-info .nav-tabs-navigation{
            margin-top: 0;
            margin-left: 30px;
        }
    }

    @media(max-width: 800px) {
        .project-item-info{
            max-width: calc(100vw - 60px);
        }

        .close .project-item-info{
            max-width: calc(100vw - 60px);
        }


    }

    @media(max-width: 670px){
        .visibility__info-left{
            width: 100%;
        }
    }

    @media(max-width: 602px){
        .project-item__info-wrp{
            width: auto;
        }

        .project-item-info .nav-tabs-wrapper{
            width: auto;
            height: 275px;
        }

        .project-item-info .vue-tabs .nav-tabs{
            width: auto;
            display: flex;
            flex-direction: column;
        }

        .project-item-info .nav-tabs-navigation{
            width: calc(100% - 60px);
        }

    }


    @media(max-width:560px){

        .project-item-info{
            margin: 0 15px;
            padding: 10px 0;
        }
        
        .project-item__title{
            margin: 0;
        }

        .nav-tabs-navigation{
            margin-left: 113px;
        }

        .project-item-info{
            max-width: calc(100vw - 90px);
        }

        .close .project-item-info{
            max-width: calc(100vw - 90px);
        }

        .project-item__info-wrp{
            margin: 0;
        }
        
        .project-item-info .nav-tabs-navigation{
            margin: 0;
            width: 100%;
        }
    }

    @media(max-width: 410px){
        .project-item__title{
            background: none;
            box-shadow: none;
        }

        .nav-tabs-navigation{
            margin-top: 0;
            margin-left: 0;
        }

        .vue-tabs .nav-tabs > li:first-child > a{
            padding-left: 25px;
            border-radius: 30px 0 0 0;
        }
    }

    @media(max-width: 308px){
        .project-item-info .vue-tabs .nav-tabs > li > a{
            font-size: 12px;
        }

        .project-item-info .vue-tabs .nav-tabs > li:first-child > a{
            padding-left: 10px;
            border-radius: 0;
        }

        .project-item-info .vue-tabs .nav-tabs > li:last-child > a{
            padding-right: 10px;
            border-radius: 0;
        }

        .project-item-info .vue-tabs .nav-tabs > li:last-child{
            border-radius: 0;
        }
    } 

    @media(max-width:458px){
        .project-item__info-wrp{
            flex-wrap: wrap;
            height: 125px;
        }

        .project-item__title{
            width: 100%;
        }
    }

    @media(max-width: 348px){
        .project-item__info-wrp {
            height: 155px;
        }
    }



</style>