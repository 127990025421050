
<template>
    <div class="project-access__wrp">
        <div class="project-access__visibility">
            <h3 class="visibility__title">Visibility</h3>
            <p class="visibility__info">Everyone can track time on public projects.</p>
            <div class="visibility__project-item">
                <p class="visibility__project-input">
                    <input type="radio" id="Public" name="visibility1" :checked="isPublic == 1" >
                    <label class="visibility__project-label" for="Public" @click="changePublic(1)">Public</label>
                </p>
                <p class="visibility__project-input">
                    <input type="radio" id="Private" name="visibility0" :checked="isPublic == 0">
                    <label class="visibility__project-label" for="Private" @click="changePublic(0)">Private</label>
                </p>
            </div>
        </div>
        <div class="project-access__members" v-if="isPublic == 1">
            <h3 class="visibility__title">Members</h3>
            <button class="project-access__add-members" @click="showAddNewMemberModal = !showAddNewMemberModal">
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.6666 8.50065C13.6666 11.6303 11.1296 14.1673 7.99998 14.1673V16.1673C12.2342 16.1673 15.6666 12.7348 15.6666 8.50065H13.6666ZM7.99998 14.1673C4.87037 14.1673 2.33331 11.6303 2.33331 8.50065H0.333313C0.333313 12.7348 3.7658 16.1673 7.99998 16.1673V14.1673ZM2.33331 8.50065C2.33331 5.37104 4.87037 2.83398 7.99998 2.83398V0.833984C3.7658 0.833984 0.333313 4.26647 0.333313 8.50065H2.33331ZM7.99998 2.83398C11.1296 2.83398 13.6666 5.37104 13.6666 8.50065H15.6666C15.6666 4.26647 12.2342 0.833984 7.99998 0.833984V2.83398Z" fill="#222222"/>
                    <path d="M6.99998 11.1673C6.99998 11.7196 7.4477 12.1673 7.99998 12.1673C8.55226 12.1673 8.99998 11.7196 8.99998 11.1673H6.99998ZM8.99998 5.83398C8.99998 5.2817 8.55226 4.83398 7.99998 4.83398C7.4477 4.83398 6.99998 5.2817 6.99998 5.83398H8.99998ZM10.6666 9.50065C11.2189 9.50065 11.6666 9.05294 11.6666 8.50065C11.6666 7.94837 11.2189 7.50065 10.6666 7.50065V9.50065ZM5.33331 7.50065C4.78103 7.50065 4.33331 7.94837 4.33331 8.50065C4.33331 9.05294 4.78103 9.50065 5.33331 9.50065V7.50065ZM8.99998 11.1673V8.50065H6.99998V11.1673H8.99998ZM8.99998 8.50065V5.83398H6.99998V8.50065H8.99998ZM7.99998 9.50065H10.6666V7.50065H7.99998V9.50065ZM7.99998 7.50065H5.33331V9.50065H7.99998V7.50065Z" fill="black"/>
                </svg>
                <span>
                    Add members
                </span>
            </button>
            <ProjectItemMembers :tasks="tasks" />
            <AddNewMemberModal v-if="showAddNewMemberModal" @close="showAddNewMemberModal = false"/>
        </div>
        <ProjectUpdateModal v-if="showProjectUpdateModal" @close="showProjectUpdateModal = false" />
    </div>
</template>

<script>
    import ProjectItemMembers from '@/components/ProjectItem/ProjectItemMembers'
    import AddNewMemberModal from '@/components/Modal/AddNewMemberModal'
    import ProjectUpdateModal from '@/components/Modal/ProjectUpdateModal'
    import { useProjectStore } from '@/store/ProjectStore'


export default {
    props: ['tasks'],
    data () {
        const projectStore = useProjectStore()
        return {
            store: projectStore,
            currentTasks: this.tasks,
            showAddNewMemberModal: false,
            isPublic: projectStore.currentProject.ip,
            showProjectUpdateModal: false
        };
    },
    components: {
        ProjectItemMembers, AddNewMemberModal, ProjectUpdateModal
    },
    methods: {
        changePublic(isPublic){
            this.isPublic = isPublic
            this.showUpdateNotification()
            this.store.updatePublic(this.store.currentProject, isPublic)
        },
        showUpdateNotification(){
            this.showProjectUpdateModal = true
            setTimeout(this.closeUpdate, 1000)
        },
        closeUpdate(){
            this.showProjectUpdateModal = false;
        },
    }
}
</script>


<style scoped>
    h3,
    p{
        margin: 0;
        padding: 0;
    }

    .project-access__wrp{
        background-color: #fff;
        box-sizing: border-box;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.07);
        padding: 20px;
        padding-bottom: 0;
        margin: 20px 30px;
        
    }

    .project-access__visibility{
        border-bottom: 2px solid #F2F3F6;
    }

    .visibility__title{
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        padding-bottom: 10px;
    }

    .visibility__info{
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        color: #BABCBF;
    }

    .visibility__project-input{
        width: 130px;
    }

    
    .visibility__project-item{
        display: flex;
        flex-direction: row;
        padding: 20px 0;
        width: 260px;
    }

    .visibility__project-label{
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        padding-left: 30px;
        position: relative;
        cursor: pointer;
    }

    input[type="radio"]{
        display: none;
    }


    .visibility__project-label:before{
        position: absolute;
        content: '';
        width: 36px;
        height: 20px;
        background-image: url('../../assets/img/radio_pas.png');
        top: 0;
        left: 0;
        background-repeat: no-repeat;
        background-position: left;
    }

    input[type="radio"]:checked ~ .visibility__project-label:before{
        background-image: url('../../assets/img/radio_act.png');
    }

    .project-access__members{
        padding-top: 20px;
        position: relative;
    }

    .project-access__add-members{
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        background: rgba(156, 237, 255, 0.1);
        border: 2px solid #9CEDFF;
        box-sizing: border-box;
        border-radius: 30px;
        width: 165px;
        height: 37px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .project-access__add-members svg{
        margin-right: 5px;
    }

    @media(max-width: 560px){
        .project-access__wrp{
            margin: 20px 0;
        }
    }

    @media(max-width: 408px) {
        .visibility__project-item{
            flex-direction: column;
            width: 100%;
        }

        .visibility__project-input{
            margin: 3px 0;
        }


    }

</style>
