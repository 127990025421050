import { defineStore } from 'pinia'
import Vue from 'vue'
import { ApiCall } from '@/store/ApiCall'

export const useGroupStore = defineStore({
    id: 'GroupStore',
    state(){ 
        return {
            list: [],
            filter: 'Show active',//'archived','all'
            search: '',
            errors: [] 
        }
    },
    getters:{
        count: function(state){
            return state.list.length
        },
        groups: function(state){
            let archivedParam = -1;
            if ( state.filter == 'Show active') archivedParam = 0;
            else if ( state.filter == 'Show archived' ) archivedParam = 1;
            if ( !state.list ) return []
            return state.list.filter((el) => { 
                return el.t && el.t.toLowerCase().indexOf(state.search.toLowerCase()) != -1
                    && (el.a == archivedParam || archivedParam == -1)
                });
        },
        filterValue: function(state){
            return state.filter
        },
        searchValue: function(state){
            return state.search
        }
    },
    actions:{
        async changeSearch(search){
            this.$patch((state) => {
                state.search = search;
            })
        },
        async changeFilter(filter){
            this.$patch((state) => {
                state.filter = filter;
            })
        },
        async listing(){
            const optionsData = await ApiCall.options()
            if ( optionsData.statusText.toLowerCase() == 'ok') {
                console.log('Load groups...')
                try  {
                    const response = await ApiCall.get(
                        'ws/{wk}/groups');
                    
                    if ( response.data.result=='ok') {
                        this.$patch((state) => {
                            state.list = response.data.message
                        })
                    }
                    else {
                        this.$patch((state) => {
                            state.errors.push(response.data.message);
                        })
                    }
                }
                catch (error) {
                    this.$patch((state) => {
                        state.errors.push(error);
                    })
                }
            } else {
                this.$patch((state) => {
                    state.errors.push(optionsData.message)
                })
            }
        },
        async insert(dataInsert){
            // data should contain title field
            if ( dataInsert['title'].length == 0) return;
            console.log(dataInsert['title'])
            
            try{
                const optionsData = await ApiCall.options()
                if ( optionsData.statusText == 'OK') {
                    console.log('Insert Method: ' + dataInsert.title)
                    const response = await ApiCall.post(
                        'ws/{wk}/groups',
                        {
                            title: dataInsert.title
                        }
                    )
                    this.$patch((state) => {
                        state.list.push(response.data.message)
                    })
                    return response
                }
            }catch(error){
                this.$patch((state) => {
                    state.errors.push(error);
                })
            }
        },
        async update(group, form){
            const optionsData = await ApiCall.options()
            if ( optionsData.statusText == 'OK') {
                const response = await ApiCall.put(
                    'ws/{wk}/groups/' +group.uk,
                    form
                )
                this.$patch((state) => {
                    for(let i=0;i<state.list.length;i++){
                        if ( state.list[i].uk == group.uk) {
                            state.list[i].t = form.title
                            break
                        }
                    }
                })
                return response
            }
        },
        async archive(group) {
            ApiCall.options().then(() => {

                this.$patch((state) => {
                    for( let i = 0; i < state.list.length; i++){
                        if ( state.list[i].uk == group.uk) {
                            console.log('Archive group: ' + state.list[i].uk)
                            state.list[i].a = state.list[i].a == 1 ? 0 : 1
                            break
                        }
                    }
                })
                
                ApiCall.delete('ws/{wk}/groups/' + group.uk)
                .then(response => {
                    if ( response.data.result!='ok')
                        this.$patch((state) => {
                            state.errors.push(response.data.message);
                        })
                })
                .catch(e => {
                    this.errors.push(e)
                })
            })
            .catch(e => {    
                this.$patch((state) => {
                    state.errors.push(e);
                })
            })
        },
        
        async addUserToGroup(groupUk, userUk){            
            try{
                const optionsData = await ApiCall.options()
                if ( optionsData.statusText == 'OK') {
                    const response = await ApiCall.post(
                        'ws/{wk}/groups/'+groupUk + '/users/'+userUk
                    )
                    this.$patch((state) => {
                        state.list.push(response.data.message)
                    })
                    return response
                }
            }catch(error){
                this.$patch((state) => {
                    state.errors.push(error);
                })
            }
        },
        async removeUserFromGroup(groupUk, userUk){         
            ApiCall.options().then(() => {
                ApiCall.delete('ws/{wk}/groups/' + groupUk+ '/users/' + userUk)
                .then(response => {
                    if ( response.data.result!='ok')
                        this.$patch((state) => {
                            state.errors.push(response.data.message);
                        })
                })
                .catch(e => {
                    this.errors.push(e)
                })
            })
            .catch(e => {    
                this.$patch((state) => {
                    state.errors.push(e);
                })
            })
        }
    }
})