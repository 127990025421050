
<template>
    <div class="project-settings__wrp">
        <div class="project-settings__item name">
            <h3 class="project-settings__title">Name</h3>
            <input class="project-settings__input" type="text" placeholder="" @blur="changeTitle" v-model="name">
        </div>
        <div class="project-settings__item client">
            <h3 class="project-settings__title">Client</h3>
            <p class="project-settings__info">Used for grouping similar projects together.</p>
            <p class="project-settings__select">
                <v-selectize v-model="client" :options="clientOptions"/>
            </p>
        </div>
        <div class="project-settings__item color">
            <h3 class="project-settings__title">Color</h3>
            <p class="project-settings__info">Use color to visually differentiate projects.</p>
            <div class="project-settings__color" @click="showSelectColorModal = !showSelectColorModal">
                <p :style="{ 'background-color': '#' + color }"></p>
                <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.59749 4.33977C3.81979 4.55428 4.18021 4.55428 4.40251 4.33977L7.58213 1.27165C7.94073 0.925629 7.68676 0.333984 7.17962 0.333984H0.820377C0.313243 0.333984 0.0592681 0.925628 0.417866 1.27165L3.59749 4.33977Z" fill="black"/>
                </svg>
                <SelectColorModal v-model="color" :updateColor="updateColor" :color="color" v-if="showSelectColorModal" @close="showSelectColorModal = false"/>
            </div>
        </div>
        <div class="project-settings__item billable">
            <h3 class="project-settings__title">Billable by default</h3>
            <p class="project-settings__info">All new entries on this project will be initially set as billable.</p>
            <p class="billiable__checkbox">
                <input type="checkbox" name="#" :checked="billable" v-model="billable" id="billiable">
                <label for="billiable">Billable</label>
            </p>
        </div>
        <div class="project-settings__item rate">
            <h3 class="project-settings__title">Project billable rate</h3>
            <p class="project-settings__info">Billable rate used for calculating billable amount for this project.</p>
            <p class="project-settings__rate">
                <input type="text" placeholder="0.0" v-model="billableRate">
                <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.75 12.25L7.75 7L1.75 1.75" stroke="#E7E7E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </p>
        </div>
        <div class="project-settings__item estimate">
            <h3 class="project-settings__title">Project estimate</h3>
            <p class="project-settings__info">Choose how you wish to track project progress (time or fixed fee budget).</p>
            <p class="project-settings__select">
                <v-selectize v-model="estimate" :options="estimateOptions"/>
            </p>
            <div class="project-settings__radio">
                <p class="project-settings__radio-input">
                    <input type="radio" id="manual" name="visibility" :checked="estimateValue == 0" @click="changeEstimate(0)">
                    <label class="project-settings__radio-label" for="manual">Manual</label>
                    <input type="text" placeholder="20" :disabled="estimateValue == 1" v-model="estimateTime">
                    <span>h</span>
                </p>
                <p class="project-settings__radio-input">
                    <input type="radio" id="based" name="visibility" :checked="estimateValue == 1" @click="changeEstimate(1)">
                    <label class="project-settings__radio-label" for="based">Task based</label>
                </p>
            </div>
        </div>
        <p class="billiable__checkbox billable">
            <input type="checkbox" name="#" id="estimate" :checked="estimateResetMonth" v-model="estimateResetMonth">
            <label for="estimate">Estimate resets every month</label>
        </p>
        <div class="project-settings__upgrade-wrp">
            <button class="project-settings__upgrade">
                <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                    <path d="M3.17797 9.29975L10.4655 0.992039C10.9585 0.430003 11.8623 0.928364 11.6647 1.6533L10.331 6.54623C10.2179 6.9613 10.4921 7.38215 10.9132 7.4395L14.3917 7.9133C14.9275 7.98628 15.1835 8.62073 14.8517 9.05338L8.77821 16.9727C8.32595 17.5624 7.39745 17.1358 7.53677 16.4024L8.40801 11.8156C8.485 11.4102 8.20612 11.0239 7.80168 10.9757L3.61984 10.4773C3.05705 10.4103 2.80142 9.72901 3.17797 9.29975Z" stroke="#222222" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0">
                    <rect width="18" height="18" />
                    </clipPath>
                    </defs>
                </svg>
                <span>Upgrade</span>
            </button>
            <p class="project-settings__upgrade-txt">Available with <span>PRO feature</span></p>
        </div>
        <ProjectUpdateModal v-if="showProjectUpdateModal" @close="showProjectUpdateModal = false" />
    </div>
</template>

<script>
    import SelectColorModal from '@/components/Modal/SelectColorModal'
    import ProjectUpdateModal from '@/components/Modal/ProjectUpdateModal'
    import 'selectize/dist/css/selectize.default.css'
    import VSelectize from '@isneezy/vue-selectize'
    import { useProjectStore } from '@/store/ProjectStore'
    import { useClientStore } from '@/store/ClientStore'


export default {
    data () {
        const projectStore = useProjectStore()
        const clientStore = useClientStore()
        return {
            client: { label: projectStore.currentProject.ct, value: projectStore.currentProject.cuk },
            name: projectStore.currentProject.t,
            color: projectStore.currentProject.cs ? projectStore.currentProject.cs : '9DE8FF',
            billable: projectStore.currentProject.idb,
            billableRate: projectStore.currentProject.br,
            estimate: projectStore.currentProject.pe == 0 ? 'Time estimate' : 'Task Based',
            estimateValue: projectStore.currentProject.pe,
            estimateTime: projectStore.currentProject.et,
            estimateResetMonth: projectStore.currentProject.erm,
            showSelectColorModal: false,
            projectStore: projectStore,
            clientStore: clientStore,
            clientOptions: [],
            estimateOptions: [],
            estimateManual: { label: 'Time estimate', value: '0' },
            estimateTaskBased: { label: 'Task Based', value: '1' },
            showProjectUpdateModal: false
        }
    },
    watch: {
        client() {
            this.projectStore.updateClient(this.projectStore.currentProject, this.client)
            this.showUpdateNotification()
        },
        name() {
            this.projectStore.update(this.projectStore.currentProject, {title: this.name})
            this.showUpdateNotification()
        },
        color() {
            this.projectStore.update(this.projectStore.currentProject, {cs: this.color})
            this.showUpdateNotification()
        },
        billable() {
            this.projectStore.update(this.projectStore.currentProject, {billable: this.billable})
            this.showUpdateNotification()
        },
        billableRate() {
            this.projectStore.update(this.projectStore.currentProject, {br: this.billableRate})
            this.showUpdateNotification()
        },
        estimate() {
            this.estimateValue = this.estimate.value
            this.projectStore.update(this.projectStore.currentProject, {estimate: this.estimate.value})
            this.showUpdateNotification()
        },
        estimateTime() {
            this.projectStore.update(this.projectStore.currentProject, {et: this.estimateTime})
            this.showUpdateNotification()
        },
        estimateResetMonth() {
            this.projectStore.update(this.projectStore.currentProject, {erm: this.estimateResetMonth})
            this.showUpdateNotification()
        }
    },
    methods: {
        changeTitle(){
            this.projectStore.update(this.projectStore.currentProject, {title: this.name })
            this.showUpdateNotification()
        },
        showUpdateNotification(){
            this.showProjectUpdateModal = true;
            setTimeout(this.closeUpdate, 1000);
        },
        closeUpdate(){
            this.showProjectUpdateModal = false;
        },
        changeEstimate(estimate){
            this.estimateValue = estimate
            this.estimate = estimate == 0 ? this.estimateManual : this.estimateTaskBased
            this.projectStore.update(this.projectStore.currentProject, {estimate: estimate})
            this.$forceUpdate()
            this.showUpdateNotification()
        },
        updateColor(color){
            this.color = color 
        }
    },
    created() {
        this.estimateOptions.push( this.estimateManual )
        this.estimateOptions.push( this.estimateTaskBased )
        this.clientStore.listing().then(() => {
            this.clients = this.clientStore.clients//.forEach(item => {item.label = item.i, item.value = item.uk})
            this.clientOptions.push({label: 'All Clients', value: ''})
            for(let i=0; i<this.clients.length; i++){
                this.clientOptions.push({label: this.clients[i]['t'], value: this.clients[i]['uk']})
            }
        })
    },
    components: {
        VSelectize, SelectColorModal, ProjectUpdateModal
    },
}
</script>


<style scoped>
    h3,
    p{
        margin: 0;
        padding: 0;
    }

    .project-settings__wrp{
        background-color: #fff;
        box-sizing: border-box;
        box-shadow: 0px 17px 28px -3px rgba(34, 60, 80, 0.07);
        margin: 0 30px 20px 30px;
    }

    .project-settings__item{
        border-bottom: 2px solid #F2F3F6;
        width: 530px;
        padding-top: 20px;
        position: relative;
        margin-left: 20px;
        padding-right: 20px;
    }

    .project-settings__item.name{
        padding-bottom: 20px;
    }

    .project-settings__title{
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        padding-bottom: 10px;
    }

    .project-settings__input{
        height: 42px;
        border: 2px solid #F2F3F6;
        box-sizing: border-box;
        border-radius: 30px;
        padding: 10px 15px;
        margin-right: 10px;
        font-family: Montserrat;
        width: 400px;
    }

    .project-settings__select{
        width: 150px;
    }

    .project-settings__info{
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        color: #BABCBF;
        padding-bottom: 10px;
    }

    .project-settings__color{
        width: 65px;
        height: 30px;
        padding: 5px 10px;
        border: 2px solid #F2F3F6;
        box-sizing: border-box;
        border-radius: 26px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .project-settings__color p{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #E058B2;
    }

    .project-settings__color svg{
        padding-left: 10px;
    }

    .billable input{
        display: none;
    }

    .billable label{
        position: relative;
        width: 36px;
        height: 20px;
        padding-left: 46px;
        font-size: 14px;
        font-weight: 600;
    }

    .billable label:after{
        position: absolute;
        content: '';
        width: 36px;
        height: 20px;
        background-image: url('../../assets/img/off.png');
        top: 0;
        left: 0;
    }

    .billable input:checked ~ label:after{
        background-image: url('../../assets/img/on.png');
    }

    .billiable__checkbox{
        margin-bottom: 20px;
    }

    .project-settings__rate{
        width: 115px;
        height: 40px;
        border: 2px solid #F2F3F6;
        border-radius: 25px;
        padding-left: 20px;
        line-height: 38px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;
        justify-content: space-between;
    }

    .project-settings__rate input{
        border: none;
        width: 50px;
        font-family: Montserrat;
    }

    .project-settings__rate svg{
        padding-right: 10px;
    }

    .project-settings__radio{
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
        width: 260px;
    }

    .project-settings__radio-label{
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        padding-left: 30px;
        position: relative;
        cursor: pointer;
    }

    .project-settings__radio-input{
        position: relative;
    }

    .project-settings__radio-input:first-child{
        padding-bottom: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .project-settings__radio-input input[type="text"]{
        border: 2px solid #F2F3F6;
        box-sizing: border-box;
        border-radius: 30px;
        width: 120px;
        height: 42px;
        margin-left: 10px;
        font-family: Montserrat;
        padding: 0 20px;
    }

    .project-settings__radio-input span{
        position: absolute;
        top: 13px;
        right: 62px;
        color: #BABCBF;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
    }

    input[type="radio"]{
        display: none;
    }


    .project-settings__radio-label:before{
        position: absolute;
        content: '';
        width: 36px;
        height: 20px;
        background-image: url('../../assets/img/radio_pas.png');
        top: 0;
        left: 0;
        background-repeat: no-repeat;
        background-position: left;
    }

    input[type="radio"]:checked ~ .project-settings__radio-label:before{
        background-image: url('../../assets/img/radio_act.png');
    }

    .billiable__checkbox.billable{
        margin-top: 10px;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 0;
        cursor: pointer;
    }

    .project-settings__upgrade{
        background: #9CEDFF1A;
        border: 2px solid #9CEDFF;
        width: 149px;
        height: 42px;
        border-radius:30px;
        font-weight: 600;
        font-size: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-family: Montserrat;
        cursor: pointer;
    }

    .project-settings__upgrade svg{
        fill: transparent;
    }

    .project-settings__upgrade span{
        padding-left: 8px;
    }

    .project-settings__upgrade-wrp{
        background: rgba(156, 237, 255, 0.2);
        padding: 10px 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .project-settings__upgrade-txt{
        padding-left: 30px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
    }

    .project-settings__upgrade-txt span{
        font-weight: 700;
    }

    @media(max-width: 800px){
        .project-settings__item{
            width: 85%;
        }

        .project-settings__input{
            width: 100%;
        }
    }

    @media(max-width: 560px){
        .project-settings__wrp{
            margin: 0 
        }

        .project-settings__upgrade-wrp{
            flex-direction: column;
            align-items: flex-start;
        }

        .project-settings__upgrade-txt{
            padding-left: 0;
            padding-top: 10px;
        }

        .project-settings__radio-input{
            justify-content: flex-start;
            width: 80%;
        }

        .project-settings__radio-input input[type="text"]{
            width: 80px;
            right: 99px;
        }
    }
</style>
