<template>
    <div class="project-tasks__wrp">
        <table>
            <thead>
                <tr>
                    <th>
                        Name
                    </th>
                    <th>
                        Assignees
                    </th>
                    <th>
                    </th>
                </tr>
                </thead>
                <tbody>
                    <tr  v-for="item in tasks" :key="item.uk">
                        <td @click="item.changeName = true" >
                            <span v-if="!item.changeName">{{ item.t }}</span>
                            <input @change="item.changeName = false" class="project-tasks__input" v-else type="text" :placeholder="item.title"></td>
                        <td>
                            <p class="projects-tasks__who">
                                <span> {{item.assigned}} </span>
                                <svg width="6" height="4" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.67799 3.87065C2.85583 4.04225 3.14417 4.04225 3.32201 3.87065L5.86571 1.41615C6.15259 1.13933 5.94941 0.666016 5.5437 0.666016H0.456301C0.0505941 0.666016 -0.152586 1.13933 0.134293 1.41615L2.67799 3.87065Z" fill="black"/>
                                </svg>
                                </p>
                            <svg @click="item.showAddNewMemberModal = !item.showAddNewMemberModal" class="projects-tasks__plus" width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 11.5C20 16.4706 15.9706 20.5 11 20.5V22.5C17.0751 22.5 22 17.5751 22 11.5H20ZM11 20.5C6.02944 20.5 2 16.4706 2 11.5H0C0 17.5751 4.92487 22.5 11 22.5V20.5ZM2 11.5C2 6.52944 6.02944 2.5 11 2.5V0.5C4.92487 0.5 0 5.42487 0 11.5H2ZM11 2.5C15.9706 2.5 20 6.52944 20 11.5H22C22 5.42487 17.0751 0.5 11 0.5V2.5Z" fill="#BABCBF"/>
                                <path d="M10 15.5C10 16.0523 10.4477 16.5 11 16.5C11.5523 16.5 12 16.0523 12 15.5H10ZM12 7.5C12 6.94772 11.5523 6.5 11 6.5C10.4477 6.5 10 6.94772 10 7.5H12ZM15 12.5C15.5523 12.5 16 12.0523 16 11.5C16 10.9477 15.5523 10.5 15 10.5V12.5ZM7 10.5C6.44772 10.5 6 10.9477 6 11.5C6 12.0523 6.44772 12.5 7 12.5V10.5ZM12 15.5V11.5H10V15.5H12ZM12 11.5V7.5H10V11.5H12ZM11 12.5H15V10.5H11V12.5ZM11 10.5H7V12.5H11V10.5Z" fill="#BABCBF"/>
                            </svg>
                            <AddNewMemberModal v-if="item.showAddNewMemberModal" @close="item.showAddNewMemberModal = false"/>
                        </td>
                        <td>
                            <svg  @click="item.isOpenMenu = !item.isOpenMenu"  class="projects-tasks__dots" width="4" height="18" viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.333313 9.00065C0.333313 8.08018 1.0795 7.33398 1.99998 7.33398C2.92045 7.33398 3.66665 8.08018 3.66665 9.00065C3.66665 9.92113 2.92045 10.6673 1.99998 10.6673C1.0795 10.6673 0.333313 9.92113 0.333313 9.00065Z" fill="#BABCBF"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.333313 2.33268C0.333313 1.41221 1.0795 0.666016 1.99998 0.666016C2.92045 0.666016 3.66665 1.41221 3.66665 2.33268C3.66665 3.25316 2.92045 3.99935 1.99998 3.99935C1.0795 3.99935 0.333313 3.25316 0.333313 2.33268Z" fill="#BABCBF"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.333313 15.6667C0.333313 14.7462 1.0795 14 1.99998 14C2.92045 14 3.66665 14.7462 3.66665 15.6667C3.66665 16.5871 2.92045 17.3333 1.99998 17.3333C1.0795 17.3333 0.333313 16.5871 0.333313 15.6667Z" fill="#BABCBF"/>
                            </svg>
                        </td>
                        <EditTaskModal v-if="item.isOpenMenu" @close="item.isOpenMenu = false"/>
                        <div class="bg" v-if="item.isOpenMenu" @close="item.isOpenMenu = false"></div>
                    </tr>
                </tbody>
        </table>
    </div>
</template>

<script>

    import AddNewMemberModal from '@/components/Modal/AddNewMemberModal'
    import EditTaskModal from '@/components/Modal/EditTaskModal'

    export default {
        props: ['tasks'],
        data () {
            return {
                showAddNewMemberModal: false,
                projectOptions: []
            }
        },
        components: {
            AddNewMemberModal, EditTaskModal
        }
    }
</script>


<style scoped>

    .project-tasks__wrp{
        color: #222222;
        min-width: 200px;
        overflow-x: auto;
        height: auto;
        margin: 10px 30px 0 30px;
        padding-right: 0;
        box-shadow: 0px -11px 39px -15px rgba(34, 60, 80, 0.1);
        position: relative;
    }

    table{
        width: 100%;
        min-width: 900px;
        margin-bottom: 150px;
        background-color: #fff;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
    }

    th{
        text-align: left;
        border-bottom: 1px solid #F2F3F6;
        color: #606569;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        padding: 20px;
    }

    th:first-child,
    th:nth-child(3),
    td:first-child,
    td:nth-child(3){
        padding:  0 20px;
    }

    
    th:first-child,
    td:first-child{
        width: 50%;
        cursor: pointer;
    }

    th:nth-child(2),
    td:nth-child(2){
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    td:nth-child(2) p{
        padding: 5px 15px 5px 15px;
        background: rgba(156, 237, 255, 0.2);
        border-radius: 20px;
    }

    .project-tasks__rate p{
        width: 115px;
        height: 40px;
        border: 2px solid #F2F3F6;
        border-radius: 25px;
        padding-left: 20px;
        line-height: 38px;
        box-sizing: border-box;
    }

    .project-tasks__rate svg{
        padding-left: 30px;
    }

    .project-tasks__rate span{
        padding-bottom: 3px;
    }

    td svg{
        padding-left: 5px;
    }

    .projects-tasks__who{
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        box-sizing: border-box;
    }

    .projects-tasks__who svg{
        padding-left: 5px;
    }

    .projects-tasks__plus{
        cursor: pointer;
        margin-left: 10px;
    }

    .projects-tasks__dots{
        cursor: pointer;
    }

    td{
        padding: 20px 5px;
        border-bottom: 1px solid #F2F3F6;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        position: relative;
    }

    tr{
        position: relative;
    }
    
    tr:last-child td{
        border-bottom: none;
    }

        
    td:nth-child(3) {
        text-align: end;
        padding-right: 20px;
    }

    .project-tasks__td svg{
        padding-bottom: 2px;
    } 

    .project-tasks__mini-modal{
        height: 78px;
        border: 1px solid #F6F7F9;
        padding: 0 15px;
        position: absolute;
        background-color: #fff;
        left: -153px;
        top: 29px;
        z-index: 13;
    }

    .project-tasks__mini-modal p{
        cursor: pointer;
        margin: 15px 0;
    }

    .project-tasks__input{
        border: 2px solid #F2F3F6;
        box-sizing: border-box;
        border-radius: 20px;
        width: 100%;
        height: 41px;
        padding: 0 20px;
        font-family: Montserrat;
    }

    @media(max-width:560px){
        .project-tasks__wrp{
            margin: 10px 0;
        }
    }

    .bg{
        width: 100vw;
        height: 100vh;
        background-color: #3d3d3d;
        opacity: 0.5;
        z-index:  19;
        position: fixed;
        top: 0;
        left: 0;
    }

    .grey{
        color: #BABCBF;
    }
</style>