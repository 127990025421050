<template>
    <div class="project-item__filters">
            <p class="project-item__select">
                <v-selectize v-model="selected" :options="['Show all tasks','Show active tasks', 'Show done tasks']"/>
            </p>
            <input class="project-item__input project-item__search" v-model="search" type="text" placeholder="Search by name">
            <button class="project-item__add" @click="$emit('toggleAddTask')">
                <svg class="clickable-svg" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.5 9C15.5 12.5899 12.5899 15.5 9 15.5V17.5C13.6944 17.5 17.5 13.6944 17.5 9H15.5ZM9 15.5C5.41015 15.5 2.5 12.5899 2.5 9H0.5C0.5 13.6944 4.30558 17.5 9 17.5V15.5ZM2.5 9C2.5 5.41015 5.41015 2.5 9 2.5V0.5C4.30558 0.5 0.5 4.30558 0.5 9H2.5ZM9 2.5C12.5899 2.5 15.5 5.41015 15.5 9H17.5C17.5 4.30558 13.6944 0.5 9 0.5V2.5Z" fill="#222222"/>
                    <path d="M8 12C8 12.5523 8.44772 13 9 13C9.55228 13 10 12.5523 10 12H8ZM10 6C10 5.44772 9.55228 5 9 5C8.44772 5 8 5.44772 8 6H10ZM12 10C12.5523 10 13 9.55228 13 9C13 8.44772 12.5523 8 12 8V10ZM6 8C5.44772 8 5 8.44772 5 9C5 9.55228 5.44772 10 6 10V8ZM10 12V9H8V12H10ZM10 9V6H8V9H10ZM9 10H12V8H9V10ZM9 8H6V10H9V8Z" fill="black"/>
                </svg>
                <span>Add new task</span>
            </button>
        </div>
</template>


<script>

    import 'selectize/dist/css/selectize.default.css'
    import VSelectize from '@isneezy/vue-selectize'
    import { useProjectStore } from '@/store/ProjectStore'
    import { useTaskStore } from '@/store/TaskStore'

    export default {
        props: ['updateFilters'],
        data() {
            const projectStore = useProjectStore()
            const taskStore = useTaskStore()
            return {
                selected: 'Show all tasks',
                search: '',
                projectStore: projectStore,
                taskStore: taskStore
            };
        },
        components: {
            VSelectize, 
        },
        watch: {
            selected() {
                this.changeFilter()
            },
            search(oldSearch, newSearch) {
                if ( oldSearch != newSearch)
                    this.changeSearch()
            }
        },
        methods:{
            changeSearch(){
                this.taskStore.changeSearch(this.search)
                this.updateFilters()
            },
            changeFilter() {
                let filter = 'active';
                switch(this.selected){
                    case 'Show active tasks':
                        filter = 'active';
                        break;
                    case 'Show done tasks':
                        filter = 'done';
                        break;
                    case 'Show all tasks':
                        filter = 'all';
                        break;
                }
                this.taskStore.changeFilter(filter)
                this.updateFilters()
            },
            openModal(){
                if( this.item == 'member'){
                    this.showAddMemberModal = !this.showAddMemberModal
                } else {
                    this.showEditGroupModal = !this.showEditGroupModal
                }
            }
        }
    }
</script>

<style scoped>

    button{
        border: none;
        background: none;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        background: #9CEDFF1A;
        border: 2px solid #9CEDFF;
        height: 42px;
        border-radius:30px;
        font-weight: 600;
        font-size: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    input{
        width: 100%;
        height: 42px;
        border: 1px solid #EAEEF1;
        font-family: 'Montserrat', sans-serif;
        border-radius: 39px;
        padding: 0 20px;
        padding-right: 30px;
        box-sizing: border-box;
        outline: none;
        max-width: 729px;
        align-self: center;
        justify-self: unset;
        background-image: url(../../assets/img/lupa.svg);
        background-repeat: no-repeat;
        background-position: 98%;
        margin: 5px;
        margin-left: 0;
    }

    .project-item__filters{
        background-color: #fff;
        align-items: center;
        justify-content: space-between;
        padding: 26px 20px;
        box-sizing: border-box;
        flex-wrap: wrap;
        display: flex;
        margin: 0 30px;
        box-shadow: 0px 18px 31px -3px rgba(34, 60, 80, 0.05);
        position: relative;
    }

    @media(max-width:560px){
        .project-item__filter{
            width: 50%; 
            margin: 0;
            height: 30px;
        }
    }
        
    .bg{
        width: 100vw;
        height: 100vh;
        background-color: #3d3d3d;
        opacity: 0.5;
        z-index:  14;
        position: fixed;
        top: 0;
        left: 0;
    }

    .project-item__add{
        width: 234px;
        margin-right: 0;
    }

    .project-item__add span{
        padding-left: 5px;
    }

    .project-item__input{
        height: 42px;
        border: 2px solid #EAEEF1;
        font-family: 'Montserrat', sans-serif;
        border-radius: 39px;
        padding: 0 20px;
        box-sizing: border-box;
        outline: none;
        align-self: center;
        justify-self: unset;
        background-repeat: no-repeat;
        background-position: 98%;
        cursor: pointer;
    }

    .project-item__search{
        background-image: url(../../assets/img/lupa.svg);
        background-repeat: no-repeat;
        background-position: 95%;
        padding-right: 30px;
        flex-grow: 1;
    }

    .project-item__select{
        margin: 0;
        position: relative;
        margin-right: 10px;
        margin-top: 5px;
    }

    @media(max-width:560px){
        .project-item-info{
            padding: 10px 15px;
        }

        .project-item__filters{
            margin: 0;
        }

        .project-item__search{
            width: 100%;
            margin-left: 0;
        }

        .project-item__add span{
            display: none;
        }

        .project-item__add{
            width: 42px;
            margin-left: 0;
        }
    }

</style>