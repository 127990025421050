<template>
        <div class="edit-project-rate-modal" v-click-outside="onClickOutside">
            <h3 class="edit-project-rate-modal--title">
                Edit rate
            </h3>
            <form class="edit-project-rate-modal__form">
                <button href="" class="close_X" @click="$emit('close')">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.33301 14.6667L7.99967 7.99999M14.6663 1.33333L7.99967 7.99999M7.99967 7.99999L14.6663 14.6667M7.99967 7.99999L1.33301 1.33333" stroke="#E7E7E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                <div class="edit-project-rate-modal__item">
                    <p class="edit-project-rate-modal__txt">
                        We'll apply this rate to all entries made by Anastasia I, except on projects that have their own rate.
                    </p>
                    <p class="edit-project-rate-modal__name">
                        <label for="">
                            Project name
                        </label>
                        <input type="text" placeholder="10">
                    </p>
                    <div class="edit-project-rate-modal__radio">
                        <p class="edit-project-rate-modal__radio-title">
                            Description:
                        </p>
                        <p class="edit-project-rate-modal__radio-input">
                            <input type="radio" id="timeVariant1" name="time">
                            <label class="edit-project-rate-modal__radio-label" for="timeVariant1">All past and future time entries</label>
                        </p>
                        <p class="edit-project-rate-modal__radio-input">
                            <input type="radio" id="timeVariant2" name="time">
                            <label class="edit-project-rate-modal__radio-label" for="timeVariant2">Time entries from</label>
                        </p>
                        <p class="edit-project-rate-modal__calendar">
                            <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 1V5M14 1V5M19 9H1M5 13H6M9.5 13H10.5M14 13H15M5 17H10.5M14 17H15M3 21H17C18.0464 21 19 20.0042 19 19V5C19 3.99585 18.1517 3 17.1053 3H3C1.95357 3 1 3.99585 1 5V19C1 20.0042 1.95357 21 3 21Z" stroke="#BABCBF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <input class="create-modal__dead-date" type="date" name="" id="">
                            <label >onwards</label>
                        </p>
                    </div>
                </div>
                <div class="buttons">
                    <button class="edit-project-rate-modal__create">
                        Create
                    </button>
                    <button @click="$emit('close')">
                        Cancel
                    </button>
                </div>
            </form>
        </div>
</template>

<script>
    import vClickOutside from 'v-click-outside'

    export default {
        data() {
            return {
            date: new Date(),
            }
        },
        props: ['id'],
        directives: {
        clickOutside: vClickOutside.directive
        },
        methods: {
        onClickOutside () {
            this.$emit('close')
        }
        }
    };
</script>

<style scoped>
    p{
        margin: 0;
        padding: 0;
    }

    .edit-project-rate-modal{
        width: 549px;
        height: 478px;
        background-color: #fff;
        position: fixed;
        top: 10%;
        padding: 20px;
        color: #222222;
        box-sizing: border-box;
        margin-bottom: 50px;
        right: calc( 50vw - 269px);
        margin-left: -273px;
        z-index: 20;
    }

    .edit-project-rate-modal--title{
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        margin: 0;
        padding-bottom: 25px;
        border-bottom: 2px solid #F2F3F6;
    }

    .edit-project-rate-modal__name{
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        text-align: left;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding-top: 10px;
    }

    .edit-project-rate-modal__item{
        display: flex;
        flex-direction: column;
        border-bottom: 2px solid #F2F3F6;
    }

    .edit-project-rate-modal__name input{
        margin-top: 10px;
        border-radius: 30px;
        height: 42px;
        border: 2px solid #F2F3F6;
        font-family: Montserrat;
        outline: none;
        padding: 0 15px;
        width: 100%;
        box-sizing: border-box;
    }

    .edit-project-rate-modal__txt{
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        background: rgba(156, 237, 255, 0.2);
        padding: 10px 20px;
        margin: 0;
        margin-top: 10px;
    }

    .edit-project-rate-modal__radio{
        margin: 20px 0;
    }

    .edit-project-rate-modal__radio-title{
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .edit-project-rate-modal__radio-input{
        margin-bottom: 10px;
    }

    .edit-project-rate-modal__radio-label{
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        padding-left: 30px;
        position: relative;
        cursor: pointer;
    }

    input[type="radio"]{
        display: none;
    }

    .edit-project-rate-modal__radio-label:before{
        position: absolute;
        content: '';
        width: 36px;
        height: 20px;
        background-image: url('../../assets/img/radio_pas.png');
        top: 0;
        left: 0;
        background-repeat: no-repeat;
        background-position: left;
    }

    input[type="radio"]:checked ~ .edit-project-rate-modal__radio-label:before{
        background-image: url('../../assets/img/radio_act.png');

    }

    button{
        border: none;
        background: none;
        cursor: pointer;
    }

    .buttons{
        display: flex;
        flex-direction: row;
    }

    .buttons button{
        margin-right: 10px;
        margin-top: 10px;
        width: 107px;
        height: 42px;
        font-family: 'Montserrat', sans-serif;
        background: #9CEDFF1A;
        border: 2px solid #9CEDFF;
        height: 42px;
        border-radius:30px;
        font-weight: 600;
        font-size: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .buttons .edit-project-rate-modal__create{
        background-color: #9CEDFF;
    }

    @media(max-width:800px){
        .bg{
            left: 0;
        }
    }

    
    @media(max-width:600px){
        .edit-project-rate-modal{
            width: 100vw;
            margin-left: -75px;
            right: 0;
            box-sizing: border-box;
        }
    }

    @media(max-width: 560px) {
        .edit-project-rate-modal{
            right: -15px;
            top: 20px;
        }
    }

    .close_X{
        position: absolute;
        right: 19px;
        top: 23px;
    }

    .create-modal__dead-date{
        margin-left: 10px;
        border: 2px solid #F2F3F6;
        box-sizing: border-box;
        border-radius: 30px;
        width: 150px;
        height: 42px;
        padding: 0 10px;
        outline: none;
        font-family: Montserrat;
        color: #222222;
    }

    .edit-project-rate-modal__calendar{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .edit-project-rate-modal__calendar label{
        padding-left: 10px;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
    }

</style>