
<template>
    <div class="project-status__wrp">
        <div class="project-status__visibility">
            <h3 class="visibility__title">Visibility</h3>
            <div class="visibility__info">
                <div class="visibility__info-left">
                    <div class="visibility__info-item estimate">
                        <p>
                            Time Estimate
                        </p>
                        <p>
                            20.00
                            <span>h</span>
                        </p>
                    </div>
                    <div class="visibility__info-item overage">
                        <p>
                            Overage
                        </p>
                        <p>
                            115.47
                            <span>h</span>
                        </p>
                    </div>
                    <div class="visibility__info-item tracked">
                        <p>
                            Tracked
                        </p>
                        <p>
                            135.47
                            <span>h</span>
                        </p>
                    </div> 
                    <div class="visibility__info-item billable">
                        <p>
                            Billable
                        </p>
                        <p>
                            115.47
                            <span>h</span>
                        </p>
                    </div>
                    <div class="visibility__info-item non-billable">
                        <p>
                            Non-billable
                        </p>
                        <p>
                            135.47
                            <span>h</span>
                        </p>
                    </div>  
                    <div class="visibility__info-item amount">
                        <p>
                            Amount
                        </p>
                        <p>
                            1,300.70
                            <span>h</span>
                        </p>
                    </div>                        
                </div>
                <div class="visibility__info-right">
                    <img src="../../assets/img/chart.png" alt="">
                </div>
            </div>
        </div>
        <div class="project-status__members">
            <h3 class="visibility__title">Tasks</h3>
            <ProjectItemStatusWrp />
        </div>
    </div>
</template>

<script>
    import ProjectItemStatusWrp from '@/components/ProjectItem/ProjectItemStatusWrp'


export default {
    data () {
        return {

        }
    },
    components: {
        ProjectItemStatusWrp
        },
    }
</script>


<style scoped>
    h3,
    p{
        margin: 0;
        padding: 0;
    }

    .project-status__wrp{
        background-color: #fff;
        box-sizing: border-box;
        box-shadow: 0px 17px 28px -3px rgba(34, 60, 80, 0.07);
        padding: 20px;
        padding-bottom: 0;
        margin: 0 30px 20px 30px;
    }

    .project-status__visibility{
        border-bottom: 2px solid #F2F3F6;
    }

    .visibility__title{
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        padding-bottom: 10px;
    }

    .visibility__project-input{
        width: 130px;
    }

    
    .visibility__project-item{
        display: flex;
        flex-direction: row;
        padding: 20px 0;
        width: 260px;
    }

    .visibility__project-label{
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        padding-left: 30px;
        position: relative;
        cursor: pointer;
    }

    input[type="radio"]{
        display: none;
    }


    .visibility__project-label:before{
        position: absolute;
        content: '';
        width: 36px;
        height: 20px;
        background-image: url('../../assets/img/radio_pas.png');
        top: 0;
        left: 0;
        background-repeat: no-repeat;
        background-position: left;
    }

    input[type="radio"]:checked ~ .visibility__project-label:before{
        background-image: url('../../assets/img/radio_act.png');
    }

    .project-status__members{
        padding-top: 20px;
    }

    .project-status__add-members{
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        background: rgba(156, 237, 255, 0.1);
        border: 2px solid #9CEDFF;
        box-sizing: border-box;
        border-radius: 30px;
        width: 165px;
        height: 37px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .project-status__add-members svg{
        margin-right: 5px;
    }

    .visibility__info{
        display: flex;
        flex-direction: row;
    }

    .visibility__info-item{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        box-sizing: border-box;
        width: 100%;
    }

    .visibility__info-item p{
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
    }

    .visibility__info-item p span{
        color: #606569;
    }

    .visibility__info-left{
        margin-top: 10px;
        margin-bottom: 30px;
        padding-right: 100px;
        width: 500px;
        box-sizing: border-box;
        border-right: 2px solid #F2F3F6;;
    }

    .visibility__info-item.estimate{
        border-bottom: 2px solid #F2F3F6;
        padding: 10px 0;
    }

    .visibility__info-item.billable,
    .visibility__info-item.overage{
        padding: 10px 0 5px 0;
    }

    .visibility__info-item.non-billable,
    .visibility__info-item.tracked{
        padding: 5px 0 10px 0;
        border-bottom: 2px solid #F2F3F6;        
    }

    .visibility__info-item.amount{
        padding-top: 20px;
    }

    .visibility__info-right{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .visibility__info-right img{
        width: 85%;
        margin-left: 100px;
        padding-bottom: 20px;
    }

    @media(max-width: 1350px){
        .visibility__info-right img{
            margin-left: 25px;
        }
    }

    @media(max-width: 1050px){
        .visibility__info{
            flex-direction: column;
        }

        .visibility__info-right img{
            margin-top: 10px;
        }

        .visibility__info-left{
            border: none;
        }
    }

    @media(max-width: 800px){
        .visibility__info-left{
            width: auto;
            padding-right: 0;
        }
    }

    @media(max-width: 560px){
        .project-status__wrp{
            margin: 0 
        }
    }
</style>
