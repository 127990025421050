<template>
  <div>
    <p>Projects</p>
    <ProjectItemWrp />
  </div>
</template>

<script>
    import ProjectItemWrp from '@/components/ProjectItem/ProjectItemWrp'

    export default {
        components: {
          ProjectItemWrp
        }
    }
</script>

<style scoped>
  p{
    margin: 0;
    padding: 0;
    padding-top: 17px;
    margin-left: 50px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }

  div{
    position: relative;
  }
</style>