<template>
    <div class="projects__wrp">
        <table>
            <thead>
                <tr>
                    <th
                    :class="sortedClass('name')"
                    @click="sortBy('name')"
                    >
                        Name
                    </th>
                    <th
                    :class="sortedClass('assignees')"
                    @click="sortBy('assignees')"
                    >
                        Assignees
                    </th>
                    <th
                    :class="sortedClass('amount')"
                    @click="sortBy('amount')"
                    >
                        Amount
                    </th>
                    <th
                    :class="sortedClass('tracked')"
                    @click="sortBy('tracked')"
                    >
                        Tracked
                    </th>
                </tr>
                </thead>
                <tbody>
                    <tr v-for="item in tasks" :key="item.id">
                        <td>{{ item.t }}</td>
                        <td>{{ item.assigness }}</td>
                        <td>{{ item.a }} USD</td>
                        <td>{{ item.t }} h</td>
                    </tr>
                </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: ['tasks'],
    data () {
        return {
        sort: {
            key: '',
            isAsc: false
        },
        /*items: [
            { id: 1, name: 'Anastasia F.', assignees: 'Anastasia F.', amount: '170.07', tracked: '21.06'},
            { id: 2, name: 'Maria D.', assignees: 'Anastasia M.', amount: '180.07', tracked: '21.07'},
            { id: 3, name: 'Danil K.', assignees: 'Anastasia N.', amount: '190.07', tracked: '21.08'},
        ]*/
        }
    },
    computed: {
        sortedItems () {
            const list = this.tasks.slice();
            if (this.sort.key) {
                list.sort((a, b) => {
                a = a[this.sort.key]
                b = b[this.sort.key]
                return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
                });
            }
            return list;
            }
        },
        methods: {
            sortedClass (key) {
                return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc' }` : '';
                },
            sortBy (key) {
                this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
                this.sort.key = key;
            }
        }
    }
</script>


<style scoped>
    .projects__wrp{
        color: #222222;
        min-width: 200px;
        overflow-x: auto;
        height: auto;
        margin-top: 10px;
        box-shadow: 0px -11px 39px -15px rgba(34, 60, 80, 0.1);
    }

    table{
        width: 100%;
        min-width: 950px;
        margin-bottom: 30px;
        background-color: #fff;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
    }

    th{
        padding: 16px 5px;
        text-align: left;
        border-bottom: 1px solid #F2F3F6;
        color: #606569;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;

    }

    th:first-child,
    th:last-child,
    td:first-child,
    td:last-child{
        padding: 20px;
    }

    td:last-child{
        cursor: pointer;
    }

    td:first-child,
    th:first-child{
        width: 66%;
    }


    td{
        padding: 20px 5px;
        border-bottom: 1px solid #F2F3F6;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        position: relative;
    }

    tr:last-child td{
        border-bottom: none;
    }

    .projects__input{
        display: none;
    }

    .projects__label{
        position: relative;
    }

    .projects__label:before{
        position: absolute;
        content: "";
        width: 14px;
        height: 14px;
        border: 2px solid #9CEDFF;
        border-radius: 4px;
        top: -10px;
        left: 0;
    }

    .projects__input:checked ~ .projects__label:before{
        background-image: url('../../assets/img/check.svg');
        background-repeat: no-repeat;
        background-position: center;
    }

    .projects__entry{
        position: relative;
    }

    .projects__entry:before{
        position: absolute;
        content:'';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #9CEDFF;
        top: 33px;
        left: -10px;
    }

    .projects__entry a{
        text-decoration: none;
        color: #222222;
        cursor: pointer;
    }

    .projects__assignees{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .projects__assignees span{
        color: #606569;
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
        padding-top: 3px;
    }

    .red{
        color: #EB5757;
    }

    .projects__mini-modal{
        width: 117px;
        height: 78px;
        border: 1px solid #F6F7F9;
        padding: 0 15px;
        position: absolute;
        background-color: #fff;
        left: -130px;
        top: 24px;
        z-index: 15;
    }

    .projects__mini-modal p{
        cursor: pointer;
    }

    @media(max-width:560px){
        .projects__wrp{
            margin: 10px 15px;
        }
    }

    .favourite{
        cursor: pointer;
    }
</style>