<template>
        <div class="user-role-modal" v-click-outside="onClickOutside">
            <h3 class="user-role-modal--title">
                User role
            </h3>
            <form class="user-role-modal__form">
                <button href="" class="close_X" @click="$emit('close')">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.33301 14.6667L7.99967 7.99999M14.6663 1.33333L7.99967 7.99999M7.99967 7.99999L14.6663 14.6667M7.99967 7.99999L1.33301 1.33333" stroke="#E7E7E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                <div class="user-role-modal__item">
                    <input class="user-role-modal__input" type="checkbox" name="" id="admin-input">
                    <label class="user-role-modal__label" for="admin-input">Admin</label>
                </div>
                <div class="user-role-modal__update">
                    <p class="user-role-modal__update-txt">STANDARD feature</p>
                    <button class="user-role-modal__update-button">Upgrade</button>
                </div>
                <div class="user-role-modal__item">
                    <input class="user-role-modal__input" type="checkbox" name="" id="admin-input" disabled>
                    <label class="user-role-modal__label" for="admin-input">
                        <p>Project manager</p> 
                        <span>Can edit all projects they manage and see all time on those projects</span>
                    </label>
                </div>
                <div class="user-role-modal__item">
                    <input class="user-role-modal__input" type="checkbox" name="" id="admin-input" disabled>
                    <label class="user-role-modal__label" for="admin-input">
                        <p>Project manager</p> 
                        <span>Can edit all projects they manage and see all time on those projects</span>
                    </label>
                </div>
                <div class="buttons">
                    <button class="user-role-modal__create">
                        Create
                    </button>
                    <button @click="$emit('close')">
                        Cancel
                    </button>
                </div>
            </form>
        </div>
</template>

<script>
    import vClickOutside from 'v-click-outside'
    
    export default {
        props: ['updateMethod'],
        data(){
            return {
                member: []
            }
        },
        directives: {
            clickOutside: vClickOutside.directive
        },
        methods: {
            onClickOutside () {
                this.$emit('close')
            },
            setMember(item) {
                this.member = item
            }
        }
    };
</script>

<style scoped>
    p{
        margin: 0;
        padding: 0;
    }

    .user-role-modal{
        width: 549px;
        height: 478px;
        background-color: #fff;
        position: fixed;
        top: 10%;
        padding: 20px;
        color: #222222;
        box-sizing: border-box;
        margin-bottom: 50px;
        z-index: 20;
        right: calc( 50vw - 269px);
        margin-left: -273px;
    }

    .user-role-modal--title{
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        margin: 0;
        padding-bottom: 25px;
        border-bottom: 2px solid #F2F3F6;
    }

    .user-role-modal__form{
        display: flex;
        flex-direction: column;
    }
    

    .user-role-modal__item{
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }

    .user-role-modal__input{
        display: none;
    }

    .user-role-modal__label{
        position: relative;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 17px;
        padding-left: 30px
    }

    .user-role-modal__label:before{
        position: absolute;
        content: "";
        width: 14px;
        height: 14px;
        border: 2px solid #9CEDFF;
        border-radius: 4px;
        top: 0;
        left: 0;
    }

    .user-role-modal__input:checked ~ .user-role-modal__label:before,
    .user-role-modal__input:disabled ~ .user-role-modal__label:before{
        background-image: url('../../assets/img/check.svg');
        background-repeat: no-repeat;
        background-position: center;
    }

    .user-role-modal__input:disabled ~ .user-role-modal__label{
        color: #BABCBF;
    }

    .user-role-modal__label span{
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        padding-top: 3px;
    }


    button{
        border: none;
        background: none;
        cursor: pointer;
    }

    .buttons{
        display: flex;
        flex-direction: row;
        margin-top: 60px;
        padding-top: 20px;
        border-top: 2px solid #F2F3F6;

    }

    .buttons button{
        margin-right: 10px;
        margin-top: 10px;
        width: 107px;
        height: 42px;
        font-family: 'Montserrat', sans-serif;
        background: #9CEDFF1A;
        border: 2px solid #9CEDFF;
        height: 42px;
        border-radius:30px;
        font-weight: 600;
        font-size: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .buttons .user-role-modal__create{
        background-color: #9CEDFF;
    }

    .user-role-modal__update{
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 20px 0 10px 0;
        background: rgba(156, 237, 255, 0.2);
        padding: 18px 20px;
        box-sizing: border-box;
        align-items: center;
    }

    .user-role-modal__update-txt{
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
    }

    .user-role-modal__update-button{
        width: 114px;
        height: 37px;
        background-color: #9CEDFF;
        border-radius: 30px;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 17px;
        margin-left: 20px;
    }

    @media(max-width:800px){
        .bg{
            left: 0;
        }
    }

    
        @media(max-width:600px){
        .user-role-modal{
            width: 100vw;
            margin-left: -75px;
            right: 0;
            box-sizing: border-box;
        }
    }

    @media(max-width: 560px) {
        .user-role-modal{
            right: -15px;
            top: 20px;
            min-height: 549px;
            height: auto;
        }
    }

    .close_X{
        position: absolute;
        right: 19px;
        top: 23px;
    }


</style>