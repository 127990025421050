<template>
    <div class="team-members__wrp">
        <table>
            <thead>
                <tr>
                    <th
                    :class="sortedClass('name')"
                    @click="sortBy('name')"
                    >
                        Name
                    </th>
                    <th
                    :class="sortedClass('rate')"
                    @click="sortBy('rate')"
                    >
                        Billable Rate
                    </th>
                    <th
                    :class="sortedClass('role')"
                    @click="sortBy('role')"
                    >
                        Role
                    </th>
                    <th>
                    </th>
                    <th>
                    </th>
                </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="item in sortedItems"
                        :key="item.id"
                    >
                        <td>{{ item.t }}</td>
                        <td class="team-members__rate" @click="item.showEditProjectRateModal = !item.showEditProjectRateModal">
                            <p v-bind:class="{ grey: item.r == '0.0' }">
                                <span>{{ item.r }} $</span>
                                <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.75 12.25L7.75 7L1.75 1.75" stroke="#E7E7E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </p>
                        </td>
                        <td class="team-members__td"  @click="item.showUserRoleModal = !item.showUserRoleModal">
                            <span>{{ item.role }}</span>
                                <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.63774 4.10521C3.83781 4.29826 4.16219 4.29826 4.36226 4.10521L7.22392 1.3439C7.54666 1.03248 7.31808 0.5 6.86166 0.5H1.13834C0.681918 0.5 0.453341 1.03248 0.776079 1.3439L3.63774 4.10521Z" fill="black"/>
                                </svg>
                        </td>
                        <td @click="checkOpenMenu(item)" >
                            <svg width="4" height="18" viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.333313 9.00004C0.333313 8.07957 1.0795 7.33337 1.99998 7.33337C2.92045 7.33337 3.66665 8.07957 3.66665 9.00004C3.66665 9.92052 2.92045 10.6667 1.99998 10.6667C1.0795 10.6667 0.333313 9.92052 0.333313 9.00004Z" fill="#606569"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.333313 2.33329C0.333313 1.41282 1.0795 0.666626 1.99998 0.666626C2.92045 0.666626 3.66665 1.41282 3.66665 2.33329C3.66665 3.25377 2.92045 3.99996 1.99998 3.99996C1.0795 3.99996 0.333313 3.25377 0.333313 2.33329Z" fill="#606569"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.333313 15.6667C0.333313 14.7462 1.0795 14 1.99998 14C2.92045 14 3.66665 14.7462 3.66665 15.6667C3.66665 16.5871 2.92045 17.3333 1.99998 17.3333C1.0795 17.3333 0.333313 16.5871 0.333313 15.6667Z" fill="#606569"/>
                            </svg>
                            <div v-if="item.openMenu" class="team-members__mini-modal" >
                                <p>Transfer ownership</p>
                                <p>Deactivate</p>
                            </div>
                        </td>
                        <td>                        
                            <EditGroupMember v-bind="item.index" v-if="item.showEditGroupMember" @close="item.showEditGroupMember = false"/>
                        </td>
                            <EditProjectRateModal  v-bind="item.index" v-if="item.showEditProjectRateModal" @close="item.showEditProjectRateModal = false"/>
                            <div class="bg" v-if="item.showEditProjectRateModal" @close="item.showEditProjectRateModal = false"></div>
                            <UserRoleModal  v-bind="item.index" v-if="item.showUserRoleModal" @close="item.showUserRoleModal = false"/>
                            <div class="bg" v-if="item.showUserRoleModal" @close="item.showUserRoleModal = false"></div>
                    </tr>
                </tbody>
        </table>

    </div>
</template>

<script>

import EditProjectRateModal from '@/components/Modal/EditProjectRateModal'
import UserRoleModal from '@/components/Modal/UserRoleModal'
import EditGroupMember from '@/components/Modal/EditGroupMember'

export default {
    props: ['tasks'],
    data () {
        return {
        sort: {
            key: '',
            isAsc: false,
            showEditProjectRateModal: false, 
            showUserRoleModal :false,
            showEditGroupMember: false,
        },
        /*items: [
            { id: 1, name: 'Prototype first version', email: 'anastasia.i.a@mail.ru', rate: '0.0', role: 'Owner', group: 'Active', openMenu: false, showEditProjectRateModal: false, showUserRoleModal : false, showEditGroupMember: false},
            { id: 2, name: 'Prototype first version', email: 'anastasia.i.a@mail.ru', rate: '10', role: 'Owner', group: 'Active', openMenu: false, showEditProjectRateModal: false, showUserRoleModal :false, showEditGroupMember: false},
            { id: 3, name: 'Prototype first version', email: 'anastasia.i.a@mail.ru', rate: '20', role: 'Owner', group: 'Active', openMenu: false, showEditProjectRateModal: false, showUserRoleModal :false, showEditGroupMember: false},
        ]*/
        }
    },
    components: {
            EditProjectRateModal, UserRoleModal, EditGroupMember
        },
    computed: {
        sortedItems () {
            const list = this.tasks.slice();
            if (this.sort.key) {
                list.sort((a, b) => {
                a = a[this.sort.key]
                b = b[this.sort.key]
                return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
                });
            }
            return list;
            }
        },
    methods: {
            sortedClass (key) {
                return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc' }` : '';
                },
            sortBy (key) {
                this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
                this.sort.key = key;
            },
            checkOpenMenu(i){
                if(i.openMenu == false){
                    this.items.forEach(item => 
                    item.openMenu = false);
                    i.openMenu = true;
                } else {
                    this.items.forEach(item => 
                    item.openMenu = false
                    );
                }
            }
        }
    }
</script>


<style scoped>
    p {
        margin: 0;
        padding: 0;
    }

    .team-members__wrp{
        color: #222222;
        min-width: 200px;
        overflow-x: auto;
        height: auto;
        margin-top: 10px;
        padding-right: 0;
        box-shadow: 0px -11px 39px -15px rgba(34, 60, 80, 0.1);

    }

    table{
        width: 100%;
        min-width: 900px;
        margin-bottom: 315px;
        background-color: #fff;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
    }

    th{
        padding: 16px 5px;
        text-align: left;
        border-bottom: 1px solid #F2F3F6;
        color: #606569;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;

    }

    th:first-child,
    th:nth-child(6),
    td:first-child,
    td:nth-child(6){
        padding: 20px;
    }

    
    th:first-child,
    td:first-child{
        width: 69%;
    }

    th:nth-child(2),
    td:nth-child(2){
        width: 19%;
    }

    td:nth-child(3){
        width: 11%;
        padding: 20px 0;
        cursor: pointer;
    }

    th:nth-child(4),
    td:nth-child(4){
        width: 10%;
        padding-left: 20px;
        cursor: pointer;
    }

    
    th:nth-child(5),
    td:nth-child(5){
        width: 10%;
        padding-left: 20px;
        cursor: pointer;

    }

    td:nth-child(6){
        text-align: center;
        cursor: pointer;

    }

    .team-members__rate p{
        width: 115px;
        height: 40px;
        border: 2px solid #F2F3F6;
        border-radius: 25px;
        padding-left: 20px;
        line-height: 38px;
        box-sizing: border-box;
        cursor: pointer;
    }

    .team-members__rate svg{
        padding-left: 30px;
    }

    .team-members__rate span{
        padding-bottom: 3px;
    }

    td svg{
        padding-left: 5px;
    }

    td{
        padding: 20px 5px;
        border-bottom: 1px solid #F2F3F6;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        position: relative;
    }

    tr{
        position: relative;
    }
    
    tr:last-child td{
        border-bottom: none;
    }

    .team-members__td svg{
        padding-bottom: 2px;
    } 

    .team-members__mini-modal{
        height: 78px;
        border: 1px solid #F6F7F9;
        padding: 0 15px;
        position: absolute;
        background-color: #fff;
        left: -153px;
        top: 29px;
        z-index: 13;
    }

    .team-members__mini-modal p{
        cursor: pointer;
        margin: 15px 0;
    }

    @media(max-width:560px){
        .team-members__wrp{
            margin: 10px 0;
        }
    }

    .bg{
        width: 100vw;
        height: 100vh;
        background-color: #3d3d3d;
        opacity: 0.5;
        z-index:  19;
        position: fixed;
        top: 0;
        left: 0;
    }

    .grey{
        color: #BABCBF;
    }
</style>