<template>
        <div class="top-modal">
            <p>Project successfully updated </p>
            <svg @click="$emit('close')" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.33325 10.6673L5.99992 6.00065M10.6666 1.33398L5.99992 6.00065M5.99992 6.00065L10.6666 10.6673M5.99992 6.00065L1.33325 1.33398" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
</template>

<script>


    export default {
        components: {
        },
        data(){
            return{

            }
        }
    }
</script>

<style scoped>

    p{
        margin: 0;
        padding: 0;
    }

    .top-modal{
        width: 100vw;
        height: 40px;
        background: #37F182;
        position: fixed;
        top: 0;
        left: 0;
        color: #000000;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        z-index: 15;
        font-size: 14px;
        font-weight: 600;
    }

    .top-modal svg{
        padding-left: 10px;
    }


</style>