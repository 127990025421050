<template>
        <div class="team-members__mini-modal"  v-click-outside="onClickOutside">
            <p @click="setAsTemplate">Set as Template</p>
            <p @click="setProjectArchived">Archive</p>
        </div>
</template>

<script>
    import vClickOutside from 'v-click-outside'
    import { useProjectStore } from '@/store/ProjectStore'
    
    export default {
        props: ['showProModal'],
        data() { 
            const projectStore = useProjectStore()
            return {
                store: projectStore
            }
        },
        directives: {
            clickOutside: vClickOutside.directive
        },
        methods: {
            setProjectArchived(){
                this.store.archive(this.store.currentProject)
                this.$emit('close')
            },
            onClickOutside () {
                this.$emit('close')
            },
            setAsTemplate () {
                this.showProModal()
                this.$emit('close')
            }
        }
    };
</script>

<style scoped>
    .team-members__mini-modal{
        height: 78px;
        border: 1px solid #F6F7F9;
        padding: 0 15px;
        position: absolute;
        background-color: #fff;
        left: 675px;
        top: 50px;
        z-index: 13;
        font-size: 14px;
        font-weight: 500;
    }

    .team-members__mini-modal p{
        cursor: pointer;
        margin: 15px 0;
        text-align: start;
    }

    @media(max-width: 1250px){
        .team-members__mini-modal{
            left: 320px;
        }
    }

    @media(max-width: 708px){
        .team-members__mini-modal{
            left: 315px;
        }
    }

    @media(max-width: 602px){
        .team-members__mini-modal{
            left: 200px;
        }
    }

    @media(max-width: 560px){
        .team-members__mini-modal{
            left: 173px;
        }
    }

    @media(max-width: 458px){
        .team-members__mini-modal{
            top: 120px;
            left: 45%;
        }
    }

    @media(max-width: 408px){
        .team-members__mini-modal{
            top: 140px;
            left: 20%;
        }
    }

    @media(max-width: 308px){
        .team-members__mini-modal{
            top: 142px;
            left: 0
        }
    }
</style>