

<template>
    <div class="project-note__wrp">
    <textarea name="" id="" cols="30" rows="10" placeholder="Note" @blur="updateNote" v-model="note"></textarea>
    <ProjectUpdateModal v-if="showProjectUpdateModal" @close="showProjectUpdateModal = false" />
    </div>
</template>

<script>
    import ProjectUpdateModal from '@/components/Modal/ProjectUpdateModal'
    import { useProjectStore } from '@/store/ProjectStore'


export default {
    data () {
        const projectStore = useProjectStore();
        
        return {
            store: projectStore,
            note: projectStore.currentProject.n,
            showProjectUpdateModal: false,
        }
    },
    components: {
        ProjectUpdateModal
    },
    methods: {
        openUpdate(){
            this.showProjectUpdateModal = true;
            setTimeout(this.closeUpdate, 1000);
        }, 
        closeUpdate(){
            this.showProjectUpdateModal = false;
        },
        updateNote() {
            this.store.updateNote(this.store.currentProject, this.note)
            this.showProjectUpdateModal = true;
            setTimeout(this.closeUpdate, 1000);
        }

    }
    }
</script>


<style scoped>
    h3,
    p{
        margin: 0;
        padding: 0;
    }

    .project-note__wrp{
        background-color: #fff;
        box-sizing: border-box;
        box-shadow: 0px 17px 28px -3px rgba(34, 60, 80, 0.07);
        padding: 20px;
        padding-bottom: 0;
        margin: 0 30px 20px 30px;
    }

    textarea{
        width: 100%;
        padding: 15px;
        box-sizing: border-box;
        margin-bottom: 50px; 
        border: 2px solid #F2F3F6;
        box-sizing: border-box;
        border-radius: 8px;
        font-family: Montserrat;
        outline: none;
    }

    @media(max-width: 560px){
        .project-note__wrp{
            margin: 0 
        }
    }

</style>
