<template>
        <div class="top-modal">
            <div class="project-settings__upgrade-wrp">
                <button class="project-settings__upgrade">
                    <!-- svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0)">
                        <path d="M3.17797 9.29975L10.4655 0.992039C10.9585 0.430003 11.8623 0.928364 11.6647 1.6533L10.331 6.54623C10.2179 6.9613 10.4921 7.38215 10.9132 7.4395L14.3917 7.9133C14.9275 7.98628 15.1835 8.62073 14.8517 9.05338L8.77821 16.9727C8.32595 17.5624 7.39745 17.1358 7.53677 16.4024L8.40801 11.8156C8.485 11.4102 8.20612 11.0239 7.80168 10.9757L3.61984 10.4773C3.05705 10.4103 2.80142 9.72901 3.17797 9.29975Z" stroke="#222222" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                        <clipPath id="clip0">
                        <rect width="18" height="18" />
                        </clipPath>
                        </defs>
                    </svg -->
                    <span>Upgrade : Available with PRO feature</span>
                </button>
            </div>
            <svg class="clickable-svg" @click="$emit('close')" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.33325 10.6673L5.99992 6.00065M10.6666 1.33398L5.99992 6.00065M5.99992 6.00065L10.6666 10.6673M5.99992 6.00065L1.33325 1.33398" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
</template>

<script>


    export default {
        components: {
        },
        data(){
            return{
            }
        }
    }
</script>

<style scoped>

    p{
        margin: 0;
        padding: 0;
    }

    .top-modal{
        width: 100vw;
        height: 40px;
        background: #37F182;
        position: fixed;
        top: 0;
        left: 0;
        color: #000000;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        z-index: 15;
        font-size: 14px;
        font-weight: 600;
    }

    .top-modal svg{
        padding-left: 10px;
    }

    .clickable-svg{
        cursor: pointer
    }


</style>