<template>
    <div class="add-new-member" v-click-outside="onClickOutside">
        <p class="add-nem-member__p">
            <span v-for="item in checkedUsers" :key="item"> {{item}} </span>
            <input type="text" >
        </p>
        <div class="add-new-member__wrp">
            <div class="add-new-member__item new-member">
                <div class="item__title">
                    <p class="item__category ">Show</p>
                    <p href="" class="item__quantity" @click="showWorkspace = !showWorkspace"> 
                        <span>
                            Active
                        </span>
                        <svg v-if="showWorkspace" width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L4.5 4L8 1" stroke="#606569" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <svg v-else width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.5 4.75L5 1.25L8.5 4.75" stroke="#606569" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </p>
                </div>
                <div v-if="showWorkspace">
                    <div class="category-item" v-for="item in show" :key="item.id" >
                        <div class="category-item__title">
                            <p class="category-item__name">
                                {{item.name}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="add-new-member__wrp">
                <p class="modal__title">
                    Users
                </p>
                <input class="modal__input" type="checkbox" id="user1" value="User1" v-model="checkedUsers">
                <label class="modal__label" for="user1">User 1</label>
                <input class="modal__input" type="checkbox" id="user2" value="User2" v-model="checkedUsers">
                <label class="modal__label" for="user2">User 2</label>
                <input class="modal__input" type="checkbox" id="user3" value="User3" v-model="checkedUsers">
                <label class="modal__label" for="user3">User 3</label>
            </div>
        </div>
    </div>
</template>

<script>
    import vClickOutside from 'v-click-outside'

    export default {
        components: {
        },
        directives: {
        clickOutside: vClickOutside.directive
        },
        methods: {
        onClickOutside () {
            this.$emit('close')
        }
        },
        data(){
            return{
                showFavTask: false,
                showHRDERTask: false,
                showCreateNewModal: false,
                showWorkspace: false,
                checkedUsers:[],
                show:{
                    ws1:{
                        id:'ws1',
                        name: 'Active',
                    },
                    ws2:{                        
                        id:'ws2',
                        name: 'Inactive',
                        },
                    ws3:{                        
                        id:'ws3',
                        name: 'All',
                        },
                },
                
            }
        },
    };
</script>

<style scoped>

    p{
        margin: 0;
        padding: 0;
    }

    .add-new-member{
        position: absolute;
        width: 335px;
        height: 320px;
        background: #fff;
        border: 2px solid #F6F7F9;
        padding: 15px;
        z-index: 30;
        top: 95px;
        left: 20px;
        box-sizing: border-box;
    }

    .project-tasks__wrp .add-new-member{
        top: 55px;
        left: -180px;
    }

    input{
        width: 100%;
        border: none;
        font-family: 'Montserrat', sans-serif;
        box-sizing: border-box;
        outline: none;
    }

    .add-nem-member__p{
        height: 42px;
        margin-bottom: 10px;
        border: 1px solid #EAEEF1;
        border-radius: 39px;
        padding: 0 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    .add-nem-member__p span{
        padding: 2px;
        border: 1px solid #9CEDFF;
        border-radius: 4px;
        margin: 0 2px;
    }

    .item__title{
        display: flex;
        flex-direction: row;
        padding-right: 5px;
    }

    .item__category{
        color: #BABCBF;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
    }

    .add-new-member__item{
        border-bottom: 2px solid #F2F3F6;
        border-top: 2px solid #F5F6F8;
        padding: 10px 0;
    }

    .item__quantity{
        margin-left: auto;
        text-decoration: none;
        font-size: 15px;
        font-weight: 600;
        line-height: 15px;
        color: #606569;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .item__quantity span{
        padding-right: 10px;
    }

    .category-item__title{
        margin-top: 10px;
        display: flex;
        flex-direction: row;
    }

    .category-item__name{
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        color: #222222;
        padding-left: 18px;
        position: relative;
    }


    .category-item__name:before{
        position: absolute;
        content: "";
        width: 9px;
        height: 9px;
        background-color: #9CEDFF;
        top: 3px;
        left: 0;
        border-radius: 50%;
    }

    .add-new-member__wrp{
        height: 235px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        margin-top: 5px;
    }

    button{
        border: none;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
    }
    
    button span{
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        color: #58C7E0;
        padding-left: 5px;
    }

    .new-member .category-item__name{
        padding-left: 0;
        cursor: pointer;
    }

    .new-member .category-item__name:before{
        width: 0;
        height: 0;
    }

    .category-item{
        padding-right: 5px;
    }

    .modal__title{
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        padding: 0;
        margin: 0;
        color: #BABCBF;
    }

    .modal__input{
        display: none;
    }

    .modal__label{
        position: relative;
        padding-left: 30px;
        margin: 5px 0;
    }

    .modal__label:before{
        position: absolute;
        content: "";
        width: 14px;
        height: 14px;
        border: 2px solid #9CEDFF;
        border-radius: 4px;
        top: 0px;
        left: 0;
    }

    .modal__input:checked + .modal__label:before{
        background-image: url('../../assets/img/check.svg');
        background-repeat: no-repeat;
        background-position: center;
    }


    @media(max-width: 1098px){
        .add-entry__tags .add-new-member{
            right: 1.3vw;
            width: 44vw;
        }
    }

    @media (max-width:999px){
        .tracker-wrp__item .add-new-member{
            left: 30px;
        }
    }

    @media(max-width:800px) {
        .add-entry__tags .add-new-member{
            width: 100%;
            right:0;
        }

        .tracker-wrp__item .add-new-member{
            left: 0;
        }
    }

    @media(max-width:650px){
        .tracker-info .add-new-member{
            left: 0;
        }
    }

    @media(max-width:615px){
        .tracker-wrp__item .add-new-member{
            top: 75px;
        }
    }

    @media(max-width:580px){
        .tracker-info .add-new-member{
            width: 100%;
        }

        .tracker-wrp__item .add-new-member{
            width: 100%;
        }

        .item__quantity span{
            text-align: end;
        }
    }

    @media(max-width:468px){
        .add-new-member{
            left: -20px;
        }
    }

    @media(max-width:426px){
        .add-new-member{
            width: 100%;
        }
    }

</style>